import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
import locale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
import VueClipboard from 'vue-clipboard2';
import i18n from './components/i18n'
import './static/font/font.css'
import translate from 'i18n-jsautotranslate'
import api from "./api/api.js";
import emailDialog from './components/emailDialog.vue';
// 检测用户代理
const isMobile = /Android|webOS|iPhone|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

if (isMobile) {
  // 跳转到手机站
  window.location.href = 'https://m.esenmart.com';
} else {
    Vue.use(ElementUI, { locale });
    Vue.use(VueClipboard);
    Vue.prototype.$translate = translate;
    Vue.prototype.$api = api;
    Vue.config.productionTip = false
    new Vue({
        router,
        i18n,
        render: h => h(App),
    }).$mount('#app')
    Vue.filter('dateFormatYmd', function(originVal, timeZone = 'Asia/Tokyo'){
        if (!originVal) {
            return '-';
        }
    
        // 先把传参毫秒转化为new Date()
        const date = new Date(originVal * 1000);
        return new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            hour12: false,
            timeZone: timeZone
        }).format(date);
    })
    Vue.filter('dateFormat', function(originVal, timeZone = 'Asia/Tokyo'){
        if (!originVal) {
            return '-';
        }
    
        const date = new Date(originVal * 1000 );
        //格式输出 //ja-JP-u-ca-japanese
        return new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: timeZone
        }).format(date);
    })
    Vue .prototype.$emailDialog = new Vue(emailDialog).$mount();
    document.body.appendChild(Vue.prototype.$emailDialog.$el);

    Vue.prototype.showLoginModal=function(){
    Vue.prototype.$emailDialog.openModal();
}
}







